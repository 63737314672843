import React from "react";
import { Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SoundWave from "../images/soundwave.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    color: "#F4F4F4",
    background: "#0149B4",
  },
  container: {},
}));

function Technology() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div
        id="mobile-technology"
        className={classes.paper}
        style={{ overflow: "hidden" }}
      >
        <Container
          style={{ paddingBottom: "50px" }}
          className={classes.container}
        >
          <h1
            style={{
              paddingTop: "64px",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "31px",
            }}
          >
            Technology
          </h1>
          <p style={{ marginTop: "32px", textAlign: "center" }}>
            Proprietary DNN model and algorithm trained on thousands of native
            speakers speech
          </p>
          <button
            onClick={() => (window.location.href = "/technology")}
            className="button-main"
            style={{
              backgroundColor: "#fff",
              color: "#055AC1",
              borderRadius: "10px",
              margin: "auto",
              display: "block",
              marginTop: "32px",
            }}
          >
            Our Technology
          </button>
          <img
            src={SoundWave}
            style={{ width: "100%", width: "100vw", marginLeft: "-16px" }}
          />
        </Container>
      </div>
      <div
        className={classes.paper}
        id="desktop-technology"
        style={{ overflow: "hidden" }}
      >
        <Container
          style={{ paddingBottom: "50px" }}
          className={classes.container}
        >
          <Grid container spacing={0}>
            <Grid
              style={{ paddingRight: "100px", display: "flex" }}
              className="technology-section"
              item
              xs={12}
              xs={12}
            >
              <div style={{ minWidth: "445px" }}>
                <h1
                  style={{
                    paddingTop: "120px",
                    textAlign: "left",
                    fontWeight: "bold",
                    fontSize: "48px",
                  }}
                >
                  Technology
                </h1>
                <p style={{ marginTop: "28px" }}>
                  Proprietary DNN model and algorithm
                  <br /> trained on thousands of native speakers speech
                </p>
                <button
                  onClick={() => (window.location.href = "/technology")}
                  className="button-main"
                  style={{
                    marginTop: 20,
                    backgroundColor: "#fff",
                    color: "#055AC1",
                    borderRadius: "10px",
                    marginBottom: "70px",
                  }}
                >
                  Our Technology
                </button>
              </div>
              <img src={SoundWave} id="soundWave" style={{ width: "794px" }} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Technology;

import React, { useState, useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import ReactStopwatch from "react-stopwatch";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faPlay,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import "chartjs-top-round-bar";
import Rise from "../images/rise.svg";
import Fall from "../images/fall.svg";
import Ear from "../images/ear.png";
import FlagUsa from "../images/flag-united-states.svg";
import FlagChina from "../images/flag-china.svg";
import FlagKorea from "../images/flag-korea.svg";
import FlagSpanish from "../images/flag-spain.svg";
import BadTone1 from "../images/chinese_tone_marks/tone1_bad.svg";
import GoodTone1 from "../images/chinese_tone_marks/tone1_good.svg";
import OkTone1 from "../images/chinese_tone_marks/tone1_ok.svg";
import BadTone2 from "../images/chinese_tone_marks/tone2_bad.svg";
import GoodTone2 from "../images/chinese_tone_marks/tone2_good.svg";
import OkTone2 from "../images/chinese_tone_marks/tone2_ok.svg";
import BadTone3 from "../images/chinese_tone_marks/tone3_bad.svg";
import GoodTone3 from "../images/chinese_tone_marks/tone3_good.svg";
import OkTone3 from "../images/chinese_tone_marks/tone3_ok.svg";
import BadTone4 from "../images/chinese_tone_marks/tone4_bad.svg";
import GoodTone4 from "../images/chinese_tone_marks/tone4_good.svg";
import OkTone4 from "../images/chinese_tone_marks/tone4_ok.svg";

function TechnologyDemo() {
  const [btn, setBtn] = useState("en");
  const [audio, setAudio] = useState(null);
  const [englishSentences, setEnglishSentences] = useState([
    "A cup of sugar makes sweet fudge",
    "Cats and dogs each hate the other",
    "The tiny girl took off her hat",
  ]);
  const [koreanSentences, setKoreanSentences] = useState([
    "치킨이 너무 맛있습니다",
    "날씨가 좋습니다",
    "안녕하세요. 반갑습니다",
  ]);
  const [chineseSentenes, setChineseSentences] = useState([
    "今天的阳光真好",
    "我最爱吃夏天的水果",
    "她喜欢去上学",
  ]);
  const [spanishSentences, setSpanishSentences] = useState([
    "Hasta pronto amigo",
    "Cómo te va",
    "me encanta café quieres ir a tomar una taza",
  ]);
  const [pinyin, setPinyin] = useState([
    "Jin1_tian1_de5_yang2_guang5_zhen1_hao3",
    "Wo3_zui4_ai4_chi1_shui2_guo3",
    "Ta1_xi3_huan1_qu4_shang4_xue2",
  ]);

  const [langIndex, setLangIndex] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [demoData, setDemoData] = useState(null);
  const [recordError, setRecordError] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  useEffect(() => {
    const randomSentence =
      englishSentences[Math.floor(Math.random() * englishSentences.length)];
    setLangIndex(englishSentences.indexOf(randomSentence));
  }, []);

  function startRecording() {
    setDemoData(null);
    setRecordError(false);
    setIsRecording(true);
    try {
      if (window.recorderInstance) {
        window.recorderInstance.clear();
        window.recorderInstance = null;
      }
      //var isChrome = !!window.chrome && !!window.chrome.webstore;
      //alert(isChrome)
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
      window.URL = window.URL || window.webkitURL;
      var audio_context = new AudioContext({ sampleRate: 16000 });
      var constraints = { audio: true, video: false };
      if (navigator.userAgent.indexOf("Chrome") != -1) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function (stream) {
            var input = audio_context.createMediaStreamSource(stream);
            var recorder = new window.Recorder(input);
            recorder.record();
            window.recorderInstance = recorder;
          })
          .catch(function (err) {
            alert(err);
          });
      } else {
        navigator.getUserMedia(constraints, function (stream) {
          var input = audio_context.createMediaStreamSource(stream);
          var recorder = new window.Recorder(input);
          recorder.record();
          window.recorderInstance = recorder;
        });
      }
    } catch (e) {
      alert("No web audio support in this browser!");
    }
  }

  function stopRecording() {
    setDemoData(null);
    setRecordError(false);
    setIsRecording(false);
    window.recorderInstance.stop();
    window.recorderInstance.exportWAV(function (audioBlob) {
      window.audioBlob = audioBlob;
      setAudio(new Audio(URL.createObjectURL(audioBlob)));
      let formData = new FormData();
      formData.append(
        "fileName",
        btn === "en"
          ? (
              englishSentences[langIndex].replace(/ /g, "_") + "-aispeakweb.wav"
            ).toLowerCase()
          : btn === "kr"
          ? (
              koreanSentences[langIndex].replace(/ /g, "_") + "-aispeakweb.wav"
            ).toLowerCase()
          : btn === "sp"
          ? (
              spanishSentences[langIndex].replace(/ /g, "_") + "-aispeakweb.wav"
            ).toLowerCase()
          : (
              pinyin[langIndex] +
              "-" +
              chineseSentenes[langIndex].replace(/ /g, "_") +
              "-aispeakweb.wav"
            ).toLowerCase()
      );
      formData.append("audio", audioBlob);
      formData.append("lang", btn);
      formData.append(
        "phrase",
        btn === "en"
          ? englishSentences[langIndex]
          : btn === "kr"
          ? koreanSentences[langIndex]
          : btn === "sp"
          ? spanishSentences[langIndex]
          : chineseSentenes[langIndex]
      );
      axios({
        data: formData,
        url: "https://analytics.aispeak.ai/demo-report",
        method: "post",
      })
        .then((res) => {
          if (res.data.status) {
            setDemoData(res.data.data);
          } else {
            // alert(res.data.message)
            setRecordError(true);
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    });
  }

  function playAudio() {
    audio.addEventListener("ended", function (event) {
      if (audio.ended) {
        setIsPaused(false);
      }
    });
    setIsPaused(true);
    audio.play();
  }

  function pauseAudio() {
    setIsPaused(false);
    audio.pause();
  }

  function Result({ data }) {
    return (
      <div>
        <Row style={{ display: "flex" }}>
          <Col md={10} style={{ display: "flex" }}>
            {data.words.map((word, k) => {
              let score = parseInt(word.scores.overall);
              let tone = parseInt(word.scores.tone);
              return (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <p className="demo-sentence">
                    <div style={{ display: "flex" }}>
                      <p
                        style={{
                          margin: 4,
                          borderBottom:
                            score <= 50
                              ? "2px solid red"
                              : score > 50 && score <= 70
                              ? "2px solid #F4BC1C"
                              : "2px solid green",
                        }}
                      >
                        {word.word}
                      </p>
                    </div>
                    <div className="demo-smallcard">
                      <span style={{ color: "#1B535C" }}>Score </span>
                      <span
                        style={{
                          color:
                            word.scores.overall <= 50
                              ? "red"
                              : word.scores.overall > 50 &&
                                word.scores.overall <= 70
                              ? "#F4BC1C"
                              : "green",
                        }}
                      >
                        {word.scores.overall}
                      </span>
                      <br />
                      <span style={{ color: "#1B535C" }}>Stress </span>
                      <span
                        style={{
                          color:
                            word.scores.prominence <= 50
                              ? "red"
                              : word.scores.prominence > 50 &&
                                word.scores.prominence <= 70
                              ? "#F4BC1C"
                              : "green",
                        }}
                      >
                        {word.scores.prominence}
                      </span>
                      <br />
                      {btn === "cn" && (
                        <>
                          <span style={{ color: "#1B535C" }}>Tone </span>
                          <span
                            style={{
                              color:
                                word.scores.tone <= 50
                                  ? "red"
                                  : word.scores.tone > 50 &&
                                    word.scores.tone <= 70
                                  ? "#F4BC1C"
                                  : "green",
                            }}
                          >
                            {word.scores.tone}
                          </span>
                          <br />
                        </>
                      )}

                      <p>
                        {btn === "cn" ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              {word.phonemes.map((letter) => {
                                return letter.phone
                                  .split("")
                                  .map((character, index) => {
                                    if (index + 1 != letter.tone_index) {
                                      return (
                                        <div style={{ height: "50px" }}>
                                          <div
                                            style={{
                                              height: "10px",
                                              marginBottom: "2px",
                                            }}
                                          ></div>
                                          <span
                                            style={{
                                              color:
                                                letter.pronunciation <= 50
                                                  ? "red"
                                                  : letter.pronunciation > 50 &&
                                                    letter.pronunciation <= 70
                                                  ? "#F4BC1C"
                                                  : "green",
                                            }}
                                          >
                                            {character}
                                          </span>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "50px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              height: "10px",
                                              marginBottom: "2px",
                                            }}
                                          >
                                            {word.tone === "tone1" && (
                                              <>
                                                {tone <= 50 ? (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={BadTone1}
                                                    alt="Bad Tone 1"
                                                  />
                                                ) : tone > 50 && tone <= 70 ? (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={OkTone1}
                                                    alt="Ok Tone 1"
                                                  />
                                                ) : (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={GoodTone1}
                                                    alt="Good Tone 1"
                                                  />
                                                )}
                                              </>
                                            )}
                                            {word.tone === "tone2" && (
                                              <>
                                                {tone <= 50 ? (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={BadTone2}
                                                    alt="Bad Tone 2"
                                                  />
                                                ) : tone > 50 && tone <= 70 ? (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={OkTone2}
                                                    alt="Ok Tone 2"
                                                  />
                                                ) : (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={GoodTone2}
                                                    alt="Good Tone 2"
                                                  />
                                                )}
                                              </>
                                            )}
                                            {word.tone === "tone3" && (
                                              <>
                                                {tone <= 50 ? (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={BadTone3}
                                                    alt="Bad Tone 3"
                                                  />
                                                ) : tone > 50 && tone <= 70 ? (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={OkTone3}
                                                    alt="Ok Tone 3"
                                                  />
                                                ) : (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={GoodTone3}
                                                    alt="Good Tone 3"
                                                  />
                                                )}
                                              </>
                                            )}
                                            {word.tone === "tone4" && (
                                              <>
                                                {tone <= 50 ? (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={BadTone4}
                                                    alt="Bad Tone 4"
                                                  />
                                                ) : tone > 50 && tone <= 70 ? (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={OkTone4}
                                                    alt="Ok Tone 4"
                                                  />
                                                ) : (
                                                  <img
                                                    style={{
                                                      width: 10,
                                                    }}
                                                    src={GoodTone4}
                                                    alt="Good Tone 4"
                                                  />
                                                )}
                                              </>
                                            )}
                                          </div>
                                          <span
                                            style={{
                                              color:
                                                letter.pronunciation <= 50
                                                  ? "red"
                                                  : letter.pronunciation > 50 &&
                                                    letter.pronunciation <= 70
                                                  ? "#F4BC1C"
                                                  : "green",
                                            }}
                                          >
                                            {character}
                                          </span>
                                        </div>
                                      );
                                    }
                                  });
                              })}
                            </div>
                          </>
                        ) : (
                          <>
                            {word.phonemes.map((letter) => {
                              return (
                                <span
                                  style={{
                                    color:
                                      letter.pronunciation <= 50
                                        ? "red"
                                        : letter.pronunciation > 50 &&
                                          letter.pronunciation <= 70
                                        ? "#F4BC1C"
                                        : "green",
                                  }}
                                >
                                  {letter.phoneme}{" "}
                                </span>
                              );
                            })}
                          </>
                        )}
                      </p>
                    </div>
                  </p>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {btn === "cn" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {word.phonemes.map((letter) => {
                            return letter.phone
                              .split("")
                              .map((character, index) => {
                                if (index + 1 != letter.tone_index) {
                                  return (
                                    <div style={{ height: 50 }}>
                                      <div
                                        style={{
                                          height: 10,
                                          marginBottom: "2px",
                                        }}
                                      ></div>
                                      <span
                                        style={{
                                          color:
                                            letter.pronunciation <= 50
                                              ? "red"
                                              : letter.pronunciation > 50 &&
                                                letter.pronunciation <= 70
                                              ? "#F4BC1C"
                                              : "green",
                                        }}
                                      >
                                        {character}
                                      </span>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: 50,
                                      }}
                                    >
                                      <div
                                        style={{
                                          height: 10,
                                          marginBottom: "2px",
                                        }}
                                      >
                                        {word.tone === "tone1" && (
                                          <>
                                            {tone <= 50 ? (
                                              <img
                                                style={{ width: 10 }}
                                                src={BadTone1}
                                                alt="Bad Tone 1"
                                              />
                                            ) : tone > 50 && tone <= 70 ? (
                                              <img
                                                style={{ width: 10 }}
                                                src={OkTone1}
                                                alt="Ok Tone 1"
                                              />
                                            ) : (
                                              <img
                                                style={{ width: 10 }}
                                                src={GoodTone1}
                                                alt="Good Tone 1"
                                              />
                                            )}
                                          </>
                                        )}
                                        {word.tone === "tone2" && (
                                          <>
                                            {tone <= 50 ? (
                                              <img
                                                style={{ width: 10 }}
                                                src={BadTone2}
                                                alt="Bad Tone 2"
                                              />
                                            ) : tone > 50 && tone <= 70 ? (
                                              <img
                                                style={{ width: 10 }}
                                                src={OkTone2}
                                                alt="Ok Tone 2"
                                              />
                                            ) : (
                                              <img
                                                style={{ width: 10 }}
                                                src={GoodTone2}
                                                alt="Good Tone 2"
                                              />
                                            )}
                                          </>
                                        )}
                                        {word.tone === "tone3" && (
                                          <>
                                            {tone <= 50 ? (
                                              <img
                                                style={{ width: 10 }}
                                                src={BadTone3}
                                                alt="Bad Tone 3"
                                              />
                                            ) : tone > 50 && tone <= 70 ? (
                                              <img
                                                style={{ width: 10 }}
                                                src={OkTone3}
                                                alt="Ok Tone 3"
                                              />
                                            ) : (
                                              <img
                                                style={{ width: 10 }}
                                                src={GoodTone3}
                                                alt="Good Tone 3"
                                              />
                                            )}
                                          </>
                                        )}
                                        {word.tone === "tone4" && (
                                          <>
                                            {tone <= 50 ? (
                                              <img
                                                style={{ width: 10 }}
                                                src={BadTone4}
                                                alt="Bad Tone 4"
                                              />
                                            ) : tone > 50 && tone <= 70 ? (
                                              <img
                                                style={{ width: 10 }}
                                                src={OkTone4}
                                                alt="Ok Tone 4"
                                              />
                                            ) : (
                                              <img
                                                style={{ width: 10 }}
                                                src={GoodTone4}
                                                alt="Good Tone 4"
                                              />
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <span
                                        style={{
                                          color:
                                            letter.pronunciation <= 50
                                              ? "red"
                                              : letter.pronunciation > 50 &&
                                                letter.pronunciation <= 70
                                              ? "#F4BC1C"
                                              : "green",
                                        }}
                                      >
                                        {character}
                                      </span>
                                    </div>
                                  );
                                }
                              });
                          })}
                        </div>
                      </>
                    )}
                  </div> */}
                </div>
              );
            })}
            <div style={{ display: "flex", marginLeft: "2%" }}>
              {data.rear_tone === "fall" ? (
                <img
                  src={Fall}
                  alt="Fall"
                  style={{
                    height: 40,
                    marginLeft: "3%",
                  }}
                />
              ) : (
                <img
                  src={Rise}
                  alt="Rise"
                  style={{
                    height: 40,
                    marginLeft: "3%",
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={9} style={{ display: "flex" }}>
            {btn === "cn" && (
              <div style={{ margin: "3%" }}>
                <h4
                  style={{
                    color:
                      data.overall <= 50
                        ? "red"
                        : data.overall > 50 && data.overall <= 70
                        ? "#F4BC1C"
                        : "green",
                    textAlign: "center",
                  }}
                >
                  {data.overall}{" "}
                </h4>
                Overall
              </div>
            )}
            <div style={{ margin: "3%" }}>
              <h4
                style={{
                  color:
                    data.fluency <= 50
                      ? "red"
                      : data.fluency > 50 && data.fluency <= 70
                      ? "#F4BC1C"
                      : "green",
                  textAlign: "center",
                }}
              >
                {data.fluency}
              </h4>
              Fluency
            </div>

            <div style={{ margin: "3%" }}>
              <h4
                style={{
                  color:
                    data.pronunciation <= 50
                      ? "red"
                      : data.pronunciation > 50 && data.pronunciation <= 70
                      ? "#F4BC1C"
                      : "green",
                  textAlign: "center",
                }}
              >
                {data.pronunciation}{" "}
              </h4>
              Pronunciation
            </div>

            {btn === "cn" && (
              <div style={{ margin: "3%" }}>
                <h4
                  style={{
                    color:
                      data.tone <= 50
                        ? "red"
                        : data.tone > 50 && data.tone <= 70
                        ? "#F4BC1C"
                        : "green",
                    textAlign: "center",
                  }}
                >
                  {data.tone}{" "}
                </h4>
                Tone
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  const Recording = () => {
    return (
      <React.Fragment>
        <p
          style={{
            color: "#1371E2",
            fontSize: "13px",
            fontWeight: "600",
            marginTop: "20px",
          }}
        >
          RECORDING…
        </p>
        <ReactStopwatch
          seconds={0}
          minutes={0}
          hours={0}
          render={({ formatted }) => {
            return (
              <div>
                <p className="record-timer">{formatted}</p>
              </div>
            );
          }}
        />
      </React.Fragment>
    );
  };

  const Recorded = () => {
    return (
      <React.Fragment>
        <p
          style={{
            color: "#1371E2",
            fontSize: "13px",
            fontWeight: "600",
            marginTop: "30px",
          }}
        >
          RECORDED
        </p>
      </React.Fragment>
    );
  };

  const RecordAgain = () => {
    return (
      <React.Fragment>
        <p
          style={{
            color: "#E21313",
            fontSize: "13px",
            fontWeight: "600",
            marginTop: "30px",
          }}
        >
          RECORD AGAIN
        </p>
      </React.Fragment>
    );
  };

  const RecordAgainText = () => {
    return (
      <React.Fragment>
        <div style={{ display: "flex", justifyContent: "" }}>
          <FontAwesomeIcon
            style={{
              marginRight: "7px",
              color: "red",
            }}
            icon={faTimesCircle}
          />
          <p
            style={{
              color: "#E21313",
              fontSize: "13px",
            }}
          >
            Make sure you’re in a quiet environment
          </p>
        </div>
      </React.Fragment>
    );
  };

  const listenAudio = async (value) => {
    let res = await axios.get(
      `https://analytics.aispeak.ai/api/v1/audio?word=${value}&language=${btn}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );
    var url = window.URL.createObjectURL(res.data);
    const audio = new Audio(url);
    audio.play();
  };

  return (
    <div id="demo">
      <h1
        style={{
          textAlign: "center",
          marginTop: "120px",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Try Our Demo
      </h1>
      <Container className="demo-container" style={{ padding: "20px 40px" }}>
        <div
          style={{
            border: "1px solid #B9B9B9",
            borderRadius: "8px",
            padding: "40px",
          }}
        >
          <div className="btn-section">
            <p>Press the recording button and say the target phrase</p>
            <div className="btn-section-right">
              <div
                onClick={() => {
                  const randomSentence =
                    englishSentences[
                      Math.floor(Math.random() * englishSentences.length)
                    ];
                  setLangIndex(englishSentences.indexOf(randomSentence));
                  setBtn("en");
                  setDemoData(null);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                  width: "48px",
                }}
              >
                <img src={FlagUsa} alt="english" />
                <p style={{ fontWeight: "bold" }}>English</p>
              </div>
              <div
                onClick={() => {
                  const randomSentence =
                    chineseSentenes[
                      Math.floor(Math.random() * chineseSentenes.length)
                    ];
                  setLangIndex(chineseSentenes.indexOf(randomSentence));
                  setBtn("cn");
                  setDemoData(null);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10%",
                  cursor: "pointer",
                  width: "48px",
                }}
              >
                <img src={FlagChina} alt="China" />
                <p style={{ fontWeight: "bold" }}>China</p>
              </div>
              <div
                onClick={() => {
                  const randomSentence =
                    koreanSentences[
                      Math.floor(Math.random() * koreanSentences.length)
                    ];
                  setLangIndex(koreanSentences.indexOf(randomSentence));
                  setBtn("kr");
                  setDemoData(null);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10%",
                  cursor: "pointer",
                  width: "48px",
                }}
              >
                <img src={FlagKorea} alt="Korea" />
                <p style={{ fontWeight: "bold" }}>Korea</p>
              </div>
              <div
                onClick={() => {
                  const randomSentence =
                    spanishSentences[
                      Math.floor(Math.random() * spanishSentences.length)
                    ];
                  setLangIndex(spanishSentences.indexOf(randomSentence));
                  setBtn("sp");
                  setDemoData(null);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10%",
                  cursor: "pointer",
                  width: "48px",
                }}
              >
                <img src={FlagSpanish} alt="Spanish" />
                <p style={{ fontWeight: "bold" }}>Spanish</p>
              </div>
            </div>
          </div>
          <Grid className="record-btn-section" container>
            <Grid item xs={12} sm={6}>
              <p style={{ color: "#818383", fontWeight: "bold" }}>
                Target Phrase
              </p>
              <div style={{ display: "flex" }}>
                <h3 style={{ color: "#222323", fontSize: "25px" }}>
                  {btn === "en"
                    ? englishSentences[langIndex]
                    : btn === "kr"
                    ? koreanSentences[langIndex]
                    : btn === "sp"
                    ? spanishSentences[langIndex]
                    : chineseSentenes[langIndex]}
                </h3>
                <img
                  onClick={() =>
                    listenAudio(
                      btn === "en"
                        ? englishSentences[langIndex]
                        : btn === "kr"
                        ? koreanSentences[langIndex]
                        : btn === "sp"
                        ? spanishSentences[langIndex]
                        : chineseSentenes[langIndex]
                    )
                  }
                  src={Ear}
                  alt="ear"
                  style={{
                    height: 40,
                    marginLeft: "3%",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div className="recording-text-desktop">
                {recordError && <RecordAgainText></RecordAgainText>}
              </div>
            </Grid>
            <Grid id="recordButton" item xs={12} sm={6}>
              <div className="record-btn-wrapper">
                <div
                  className="recording-text-desktop"
                  style={{ float: "left" }}
                >
                  {isRecording && <Recording></Recording>}
                </div>
                <div
                  className="recording-text-desktop"
                  style={{ float: "left" }}
                >
                  {!!demoData && <Recorded></Recorded>}
                </div>
                <div
                  className="recording-text-desktop"
                  style={{ float: "left" }}
                >
                  {recordError && <RecordAgain></RecordAgain>}
                </div>
                {isRecording ? (
                  <div
                    className={"btn-animate record-btn"}
                    style={{
                      float: "right",
                      height: "60px",
                      width: "60px",
                      backgroundColor: "#2d4fe5",
                      borderRadius: "50%",
                      margin: "13px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      stopRecording();
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        height: "23px",
                        width: "23px",
                        borderRadius: "50%",
                        margin: "18px",
                        color: "#fff",
                      }}
                      icon={faMicrophone}
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    <div style={{ margin: "0 4%" }}>
                      <div
                        className={"record-btn"}
                        style={{
                          float: "right",
                          height: "60px",
                          width: "60px",
                          backgroundColor: "#2d4fe5",
                          borderRadius: "50%",
                          margin: "13px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          startRecording();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            height: "23px",
                            width: "23px",
                            borderRadius: "50%",
                            margin: "18px",
                            color: "#fff",
                          }}
                          icon={faMicrophone}
                        />
                      </div>
                    </div>
                    <div style={{ marginRight: "4%" }}>
                      {!!demoData && (
                        <>
                          {isPaused ? (
                            <div
                              onClick={() => pauseAudio()}
                              className={"btn-animate record-btn"}
                              style={{
                                float: "right",
                                height: "60px",
                                width: "60px",
                                backgroundColor: "#2d4fe5",
                                borderRadius: "50%",
                                margin: "13px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                pauseAudio();
                              }}
                            >
                              <FontAwesomeIcon
                                style={{
                                  height: "23px",
                                  width: "23px",
                                  borderRadius: "50%",
                                  margin: "18px",
                                  color: "#fff",
                                }}
                                icon={faPause}
                              />
                            </div>
                          ) : (
                            <div
                              className={"record-btn"}
                              style={{
                                float: "right",
                                height: "60px",
                                width: "60px",
                                backgroundColor: "#2d4fe5",
                                borderRadius: "50%",
                                margin: "13px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                playAudio();
                              }}
                            >
                              <FontAwesomeIcon
                                style={{
                                  height: "23px",
                                  width: "23px",
                                  borderRadius: "50%",
                                  margin: "20px",
                                  color: "#fff",
                                }}
                                icon={faPlay}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="recording-text">
                {isRecording && <Recording></Recording>}
                {recordError && <RecordAgain></RecordAgain>}
                {recordError && <RecordAgainText></RecordAgainText>}
                {!!demoData && <Recorded></Recorded>}
              </div>
            </Grid>
          </Grid>
          <div>{demoData && <Result data={demoData}></Result>}</div>
        </div>
      </Container>
    </div>
  );
}

export default TechnologyDemo;

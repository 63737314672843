import React from "react";
import { Grid, Container } from "@material-ui/core";
import Developer from "../images/Illustration_Developer.svg";
import Illustration_student from "../images/Illustration_Student.svg";
import Illustration_Professional from "../images/Illustration_Professional-1.svg";
import Check from "../images/Checkmark.svg";

function Main() {
  return (
    <Container id="aispeakCanBeUsed">
      <div className="ai-uses">
        <p
          style={{
            color: "#222323",
            fontSize: "31px",
            fontWeight: 600,
            textAlign: "center",
            marginBottom: "64px",
          }}
        >
          AISpeak can be used for
        </p>
        <Grid
          container
          className="boxUsecase"
          style={{ boxShadow: "0 2px 24px 8px rgba(3, 98, 203, 0.06)" }}
        >
          <Grid item xs={12} sm={6}>
            <img
              className="boxUsecase-img"
              style={{
                padding: "40px 20px 0px 20px",
                width: "100%",
                height: "401px",
              }}
              src={Developer}
              alt="Widget"
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            <h2
              className="titleMessage"
              style={{ marginTop: "50px", fontWeight: "600", fontSize: "25px" }}
            >
              Educational Contents Developers
            </h2>

            <p
              style={{
                marginTop: "24px",
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Language Learning application
            </p>
            <p
              style={{
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              {" "}
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Adaptive to different user groups – Korean, Chinese, Kids, K-12
              Students
            </p>
            <p
              style={{
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              {" "}
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Real-time and concurrent feedback
            </p>
          </Grid>
        </Grid>

        <Grid
          id="desktopSchoolAndEducation"
          container
          className="boxUsecase"
          style={{
            boxShadow: "0 2px 24px 8px rgba(3, 98, 203, 0.06)",
            marginTop: "50px",
          }}
        >
          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            <h2
              className="titleMessage"
              style={{
                marginTop: "100px",
                fontWeight: "600",
                fontSize: "25px",
              }}
            >
              School and Educational Institutions
            </h2>

            <p
              style={{
                marginTop: "24px",
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Entry placement screening or assignment and examination scoring
            </p>
            <p
              style={{
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Standardized and consistent scoring, no human bias
            </p>
            <p
              style={{
                marginBottom: "100px",
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Reduce human teachers’ labour hours, increase efficiency and save
              cost
            </p>
          </Grid>
          <Grid item sm={6} xs={12}>
            <img
              id="mobileSchoolAndEducationImg"
              style={{
                padding: "40px 20px 0px 20px",
                width: "100%",
                height: "401px",
              }}
              src={Illustration_student}
              alt="Main"
            />
          </Grid>
        </Grid>

        <Grid
          container
          id="schoolAndEducation"
          className="boxUsecase"
          style={{
            boxShadow: "0 2px 24px 8px rgba(3, 98, 203, 0.06)",
            marginTop: "50px",
          }}
        >
          <Grid item sm={6} xs={12}>
            <img
              id="mobileSchoolAndEducationImg"
              style={{
                padding: "40px 20px 0px 20px",
                width: "100%",
                height: "401px",
              }}
              src={Illustration_student}
              alt="Main"
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            <h2
              className="titleMessage"
              style={{
                marginTop: "100px",
                fontWeight: "600",
                fontSize: "25px",
              }}
            >
              School and Educational Institutions
            </h2>

            <p
              style={{
                marginTop: "24px",
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Entry placement screening or assignment and examination scoring
            </p>
            <p
              style={{
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Standardized and consistent scoring, no human bias
            </p>
            <p
              id="reduceHuman"
              style={{
                marginBottom: "100px",
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Reduce human teachers’ labour hours, increase efficiency and save
              cost
            </p>
          </Grid>
        </Grid>

        <Grid
          id="standardized"
          className="boxUsecase"
          container
          style={{
            boxShadow: "0 2px 24px 8px rgba(3, 98, 203, 0.06)",
            marginTop: "50px",
          }}
        >
          <Grid item sm={6} xs={12}>
            <img
              className="standardized-img"
              style={{
                padding: "40px 20px 0px 20px",
                width: "100%",
                height: "401px",
              }}
              src={Illustration_Professional}
              alt="Main"
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ padding: "20px" }}>
            <h2
              className="titleMessage"
              style={{
                marginTop: "100px",
                fontWeight: "600",
                fontSize: "25px",
              }}
            >
              Standardized Examinations and Assessment
            </h2>

            <p
              style={{
                marginTop: "24px",
                marginBottom: "20px",
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              For examinations like TOEFL, TOEIC, IELTS or professional
              requirements
            </p>
            <p
              id="Obtain-scores"
              style={{
                marginBottom: "100px",
                color: "#818383",
                position: "relative",
                paddingLeft: "28px",
              }}
            >
              <span
                style={{
                  boxShadow: "0 0 4px 3px rgba(17, 85, 161, 0.06)",
                  height: "20px",
                  width: "20px",
                  display: "inline-block",
                  padding: "5px",
                  textAlign: "center",
                  borderRadius: "10px",
                  position: "absolute",
                  top: "2px",
                  left: 0,
                }}
              >
                <img src={Check} style={{ display: "block" }} />
              </span>
              Obtain scores for large group of users (up to thousands)
              simultaneously
            </p>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default Main;

import React from "react";

function TechMain() {
  return (
    <div
      className="techMainContainer"
      style={{
        textAlign: "center",
      }}
    >
      <h1
        className="techTitle"
        style={{ fontWeight: "bold", fontSize: "61px", marginTop: "20px" }}
      >
        Technology
      </h1>
      <p style={{ fontSize: "20px", marginTop: "28px", color: "#818383" }}>
        Precise, Practical, Realtime, Adaptive
      </p>
    </div>
  );
}

export default TechMain;

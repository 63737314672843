import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Illustration1 from "../../images/Illustration_point1.svg";
import LargeStressMissed from "../../images/large-stress_missed.png";
import LargeStressOk from "../../images/large-stress_ok.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "./chart";
import {
  faMicrophone,
  faPlay,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UseCasevocabulary() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="tech-usecase-img-container" onClick={handleOpen}>
        <img
          style={{ maxHeight: "160px", marginBottom: "35px" }}
          src={Illustration1}
          alt="Widget"
        />
      </div>
      <Modal
        style={{ outline: "none" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // disableScrollLock={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="vocabulary">
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: "#222323",
                width: "22px",
                heigt: "2px",
                marginTop: "40px",
                marginRight: "40px",
                display: "block",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            />
            <h1>Vocabulary</h1>
            <div id="vocabularyMain">
              <div className="vocabulary-div1">
                <h3>Enunciate</h3>
                <p>/əˈnənsēˌāt/</p>
                <p>Verb</p>
                <p>
                  say or pronounce clearly "she enunciated each word slowly"
                </p>
              </div>
              <div className="vocabulary-div2">
                <FontAwesomeIcon
                  style={{
                    margin: "142px 0px 136px 11px",
                    color: "#B9B9B9",
                    height: "16px",
                    width: "11px",
                  }}
                  icon={faPlay}
                />
                <div
                  className={"btn-animate record-btn"}
                  style={{
                    height: "60px",
                    width: "60px",
                    backgroundColor: "#2d4fe5",
                    borderRadius: "50%",
                    margin: "112px 28px 102px 26px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      width: "11px",
                      height: "16px",
                      height: "23px",
                      width: "23px",
                      borderRadius: "50%",
                      margin: "18px",
                      color: "#fff",
                    }}
                    icon={faMicrophone}
                  />
                </div>
                <FontAwesomeIcon
                  style={{
                    margin: "142px 8px 136px 0px",
                    color: "#B9B9B9",
                    height: "16px",
                    width: "11px",
                  }}
                  icon={faPlay}
                />
              </div>
              <div className="vocabulary-div3">
                <div>Score</div>
                <Chart />
                <p>
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      top: "17px",
                    }}
                  >
                    <span style={{ color: "#E21313" }}>IH0</span> N A
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={LargeStressOk}
                        alt="icon"
                        style={{
                          width: "20px",
                          height: "15px",
                        }}
                      />
                      H
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      top: "17px",
                    }}
                  >
                    1 N S IY0 E
                  </div>
                  <div style={{ display: "inline-block" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={LargeStressMissed}
                        alt="icon"
                        style={{
                          width: "20px",
                          height: "15px",
                        }}
                      />
                      Y
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-block",
                      position: "relative",
                      top: "17px",
                    }}
                  >
                    2 T
                  </div>
                </p>
                <div
                  className="vocabulary-div4"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={LargeStressOk}
                    alt="icon"
                    style={{ width: "40px", height: "20px", marginTop: "5px" }}
                  />
                  <h6>Correctly stressed the syllable</h6>
                </div>
                <div
                  className="vocabulary-div4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2%",
                  }}
                >
                  <img
                    src={LargeStressMissed}
                    alt="icon"
                    style={{ width: "40px", height: "20px", marginTop: "5px" }}
                  />
                  <h6>Missed the stress</h6>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

import React from "react";
import TechnologyMain from "../components/technology-main";
import TechnologyCard from "../components/technology-card";
import TechnologyBand from "../components/technology-band";
import TechnologyFeature from "../components/technology-feature";
import TechnologyUsecase from "../components/technology-usecase";
import Technologylowerband from "../components/technology-lowerband";
import TechnologyFeatureGraph from "../components/technology-img-feature";
import TechnologyFlow from "../components/technology-flow";
import TechnologyDemo from "../components/technology-demo";
import TechnologyBandGreen from "../components/technology-band-green";

function Technology() {
  return (
    <>
      <div className="technology-background">
        <TechnologyMain></TechnologyMain>
        <TechnologyCard></TechnologyCard>
      </div>

      <TechnologyBand></TechnologyBand>
      <TechnologyFlow></TechnologyFlow>
      <TechnologyFeature></TechnologyFeature>
      <TechnologyBandGreen></TechnologyBandGreen>
      <TechnologyDemo></TechnologyDemo>
      <TechnologyFeatureGraph></TechnologyFeatureGraph>
      <TechnologyUsecase></TechnologyUsecase>
      <Technologylowerband></Technologylowerband>
    </>
  );
}

export default Technology;

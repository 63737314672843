import React from "react";
import { Container } from "@material-ui/core";
import FeaturegraphDesktop from "../images/Graph_Features_new.svg";
function TechnologyFeatureGraph() {
  return (
    <>
      <Container
        id="technologyFeatureContainer"
        style={{
          padding: "100px 0px",
          backgroundColor: "#F8FAFE",
          maxWidth: "100%",
        }}
      >
        <h1
          className="feature-title"
          style={{ textAlign: "center", marginBottom: "80px" }}
        >
          Features
        </h1>
        <div
          className="feature-graph"
          style={{
            textAlign: "center",
            position: "relative",
            padding: "100px 0px",
          }}
        >
          <div
            className="tech-img-feature"
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: "3%",
            }}
          >
            <h5
              style={{
                fontSize: "20px",
                color: "#0149B4",
                fontWeight: "bold",
              }}
            >
              Word Level Score
            </h5>
            <p>Pronunciation Score for a Word</p>
          </div>

          <div
            className="tech-img-feature"
            style={{
              position: "absolute",
              left: "6%",
              top: "20%",
            }}
          >
            <h5
              style={{
                fontSize: "20px",
                color: "#0149B4",
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              Sound Level Score
            </h5>
            <p style={{ width: "256px", textAlign: "right" }}>
              Pronunciation score for phonemes
            </p>
          </div>

          <div
            className="tech-img-feature"
            style={{
              position: "absolute",
              left: "14%",
              top: "74%",
            }}
          >
            <h5
              style={{
                fontSize: "20px",
                color: "#0149B4",
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              Tones
            </h5>
            <p>Chinese has 4 tones</p>
          </div>

          <div
            className="tech-img-feature"
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: "89%",
            }}
          >
            <h5
              style={{
                fontSize: "20px",
                color: "#0149B4",
                fontWeight: "bold",
              }}
            >
              Missed Word
            </h5>
            <p>Missed word in a speech</p>
          </div>

          <div
            className="tech-img-feature"
            style={{
              position: "absolute",
              left: "4%",
              top: "47%",
            }}
          >
            <h5
              style={{
                fontSize: "20px",
                color: "#0149B4",
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              Intonation
            </h5>
            <p>Rising and falling of tones</p>
          </div>

          <div
            className="tech-img-feature"
            style={{
              position: "absolute",
              left: "71%",
              top: "20%",
            }}
          >
            <h5
              style={{
                fontSize: "20px",
                color: "#0149B4",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Fluency
            </h5>
            <p>Articulation & pace of speech</p>
          </div>
          <div
            className="tech-img-feature"
            style={{
              position: "absolute",
              left: "78%",
              top: "45%",
            }}
          >
            <h5
              style={{
                fontSize: "20px",
                color: "#0149B4",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Homograph
            </h5>
            <p style={{ width: "210px", textAlign: "left" }}>
              Words with same spelling, different pronunciation
            </p>
          </div>
          <div
            className="tech-img-feature"
            style={{
              position: "absolute",
              left: "71%",
              top: "74%",
            }}
          >
            <h5
              style={{
                fontSize: "20px",
                color: "#0149B4",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Rhythm
            </h5>
            <p>Articulation & pace of speech</p>
          </div>
        </div>
      </Container>
      <Container
        id="technologyFeatureContainer-desktop"
        style={{
          padding: "0px 0px",
          margin: "0px 0px !important",
          backgroundColor: "#F8FAFE",
          maxWidth: "100%",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            paddingTop: "120px",
            fontSize: "32px",
            fontWeight: "600",
            marginBottom: "0px",
          }}
        >
          Features
        </h1>
        <img
          // style={{
          //   height: "774px",
          //   position: "relative",
          //   margin: "80px 202px 120px 218px",
          // }}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "60%",
            marginTop: "80px",
            paddingBottom: "120px",
            position: "relative",
            left: "2vw",
          }}
          src={FeaturegraphDesktop}
          alt="Widget"
        />
      </Container>
    </>
  );
}

export default TechnologyFeatureGraph;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import TechnologyIcon1 from "../images/Icon_Technology1.svg";
import TechnologyIcon2 from "../images/Icon_Technology2.svg";
import TechnologyIcon3 from "../images/Icon_Technology3.svg";
import TechnologyIcon4 from "../images/Icon_Technology4.svg";

function TechnologyFeature() {
  return (
    <Container>
      <div
        className="technologyFeature"
        style={{
          marginTop: "100px",
          paddingBottom: "150px",
          textAlign: "center",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sm={3}>
            <img
              id="technologyFeatureImage"
              style={{ height: "81px" }}
              src={TechnologyIcon1}
              alt="Widget"
            />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                marginTop: "58px",
                fontWeight: "bold",
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              LSTM | End-to-End Acoustic Model
            </h2>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img
              style={{ height: "81px" }}
              src={TechnologyIcon2}
              alt="Widget"
            />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                marginTop: "58px",
                fontWeight: "bold",
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Audio Feature Extraction
            </h2>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img
              style={{ height: "81px" }}
              src={TechnologyIcon3}
              alt="Widget"
            />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                marginTop: "58px",
                fontWeight: "bold",
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Adaptive Scoring Conversion
            </h2>
          </Grid>

          <Grid item xs={12} sm={3}>
            <img
              style={{ height: "81px" }}
              src={TechnologyIcon4}
              alt="Widget"
            />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                marginTop: "58px",
                fontWeight: "bold",
                width: "80%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Expert Approval
            </h2>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default TechnologyFeature;

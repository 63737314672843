import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Illustration5 from "../../images/Illustration_point5.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UseCasePresentation() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="tech-usecase-img-container" onClick={handleOpen}>
        <img src={Illustration5} alt="Widget" />
      </div>
      <Modal
        style={{ outline: "none" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // disableScrollLock={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="presentation">
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: "#222323",
                width: "22px",
                heigt: "2px",
                marginTop: "40px",
                marginRight: "40px",
                display: "block",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            />
            <h1>Paragraph</h1>
            <div>
              <p>
                They walked the whole night long, and
                <span className="blue-span">
                  {" "}
                  by break of day came once more to their father’s house.
                </span>{" "}
                They knocked at the door, and when the woman opened{" "}
                <span className="red-span">it</span> and saw that{" "}
                <span className="red-span">it</span> was Hansel and Gretel, she
                said:{" "}
                <span className="blue-span">
                  ‘You naughty children, why have you slept so long in the
                  forest?–we thought you were never coming back at all!’
                </span>{" "}
                The father, however, rejoiced, for it had cut him to the heart{" "}
                <span className="red-span">to</span> leave them behind alone.
                <br /> <br />
                Not long afterwards,{" "}
                <span className="red-span">
                  there was once more great dearth
                </span>{" "}
                throughout the land, and{" "}
                <span className="blue-span">
                  the children heard their mother saying at night to their
                  father
                </span>
                : ‘Everything is eaten again,we have{" "}
                <span className="red-span">one half loaf left</span>, and that
                is the end. The children must go, we will take them{" "}
                <span className="red-span">farther</span> into the wood, so that{" "}
                <span className="red-span">
                  they will not find their way out again
                </span>
                ; there is{" "}
                <span className="blue-span">
                  no other means of saving ourselves
                </span>
                !’
                <br /> <br />{" "}
                <span className="red-span">
                  The man’s heart was heavy, and he thought
                </span>
                : ‘It would be better for you to share the last{" "}
                <span className="red-span">mouthful</span> with your children.’
              </p>
              <div className="presentationContainer">
                <div className="presentation-stats">
                  <h1>
                    Completeness <span style={{ color: "#FFE131" }}> 78</span>
                  </h1>
                  <div>
                    <div
                      style={{ backgroundColor: "#FFE131", width: "78%" }}
                    ></div>
                  </div>
                  <p>
                    Missed words:{" "}
                    <span className="red-span">it, to, farther</span>
                  </p>
                </div>
                <div className="presentation-stats">
                  <h1>
                    Accuracy <span style={{ color: "#1371E2" }}> 82</span>
                  </h1>
                  <div>
                    <div
                      style={{ backgroundColor: "#1371E2", width: "82%" }}
                    ></div>
                  </div>
                  <p style={{ marginBottom: "38px" }}>
                    Words to practice: great, dearth, half, loaf, mouthful
                  </p>
                </div>
                <div className="presentation-stats">
                  <h1>
                    Fluency <span style={{ color: "#1371E2" }}> 90</span>
                  </h1>
                  <div>
                    <div
                      style={{ backgroundColor: "#1371E2", width: "90%" }}
                    ></div>
                  </div>
                  <p>Stutters: None</p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import Illustration4 from "../images/Illustration_point4.svg";
import UseCasevocabulary from "./useCaseComponents/vocabulary";
import UseCaseQandA from "./useCaseComponents/q&a";
import UseCasePhrases from "./useCaseComponents/phrases";
import UseCasePresentation from "./useCaseComponents/presentation";
function TechnologyUsecase() {
  return (
    <Container>
      <div
        id="technology-usecase"
        className="technology-usecase"
        style={{
          marginTop: "100px",
          paddingBottom: "150px",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontWeight: "bold", marginBottom: "124px" }}>Use Cases</h1>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}>
            <UseCasevocabulary />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                width: "80%",
                margin: "auto",
              }}
            >
              Vocabulary
            </h2>
          </Grid>
          <Grid item xs={12} sm={4}>
            <UseCasePhrases />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                width: "80%",
                margin: "auto",
              }}
            >
              Phrases
            </h2>
          </Grid>
          <Grid item xs={12} sm={4}>
            <UseCaseQandA type={"conversation"} />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                width: "80%",
                margin: "auto",
              }}
            >
              Conversation
            </h2>
          </Grid>
        </Grid>
        <Grid id="technology-row" container spacing={0}>
          <Grid item xs={12} sm={4}>
            <div id="tech-usecase-img-speech">
              <img src={Illustration4} alt="Widget" />
            </div>
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                width: "80%",
                margin: "auto",
              }}
            >
              Speech Comparison
            </h2>
          </Grid>
          <Grid item xs={12} sm={4}>
            <UseCasePresentation />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                width: "80%",
                margin: "auto",
              }}
            >
              Presentation, Speech, Lecture, Interview
            </h2>
          </Grid>
          <Grid item xs={12} sm={4}>
            <UseCaseQandA type={"qna"} />
            <h2
              style={{
                color: "#222323",
                fontSize: "20px",
                width: "80%",
                margin: "auto",
              }}
            >
              Q&A
            </h2>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default TechnologyUsecase;

import React from "react";
import Container from "@material-ui/core/Container";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar";
import Foot from "./components/footer";

import Home from "./pages/home";
import Technology from "./pages/technology";
import contact from "./pages/contact";
import Pronunciation from "./pages/pronunciation-challenge";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Container>
          <Navbar></Navbar>
        </Container>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/technology" component={Technology} exact />
          <Route path="/contact" component={contact} exact />
        </Switch>
        <Foot />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;

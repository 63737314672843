import React from "react";
import { AppBar } from "@material-ui/core";
import * as ReactBootStrap from "react-bootstrap";
import { Container } from "@material-ui/core";
import AISpeak from "../images/aispeak_logo_black-01.svg";
import { useHistory } from "react-router-dom";

function Navbarsection() {
  let history = useHistory();
  return (
    <AppBar style={{ backgroundColor: "#fff", boxShadow: "none" }}>
      <Container>
        <ReactBootStrap.Navbar collapseOnSelect expand="lg" sticky="top">
          <ReactBootStrap.Navbar.Brand href="/">
            <img id="aispeakLogo" src={AISpeak} alt="Widget" />
          </ReactBootStrap.Navbar.Brand>

          <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
            <ReactBootStrap.Nav className="mr-auto"></ReactBootStrap.Nav>
            <ReactBootStrap.Nav>
              <ReactBootStrap.Nav.Link
                eventKey="1"
                className="nav-link"
                style={{
                  color: "#222323",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
                href=""
                onClick={() => history.push("/")}
              >
                Home
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link
                eventKey="2"
                className="nav-link"
                style={{
                  color: "#222323",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
                href=""
                onClick={() => history.push("/technology")}
              >
                Technology
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link
                eventKey="4"
                className="nav-link"
                style={{
                  color: "#222323",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  marginRight: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
                href=""
                onClick={() => history.push("/")}
              >
                Resources
              </ReactBootStrap.Nav.Link>
              <ReactBootStrap.Nav.Link eventKey="5" className="nav-button">
                <button
                  onClick={() => history.push("/contact")}
                  style={{
                    padding: "10px 15px",
                    backgroundColor: "#1371E2",
                    fontSize: "13px",
                  }}
                  className="button-main nav-button"
                >
                  Contact Us
                </button>
              </ReactBootStrap.Nav.Link>
            </ReactBootStrap.Nav>
          </ReactBootStrap.Navbar.Collapse>
        </ReactBootStrap.Navbar>
      </Container>
    </AppBar>
  );
}
export default Navbarsection;

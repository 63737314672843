import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import TechnologyIcon1 from "../images/Illustration_point4.svg";
import CaptureAudio from "../images/tech-flow/Group 47.svg";
import SendAudioToServer from "../images/tech-flow/Group 22.svg";
import AnalysisOnServer from "../images/tech-flow/Group 27.svg";
import ResultBackToClient from "../images/tech-flow/Group 25.svg";
import ParseData from "../images/tech-flow/Group 55.svg";
import DisplayInMeaningfulManner from "../images/tech-flow/Group 37.svg";
import GraphAPI from "../images/Graph_API Table.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function TechnologyFlow() {
  const [index, setIndex] = React.useState(0);
  return (
    <div
      id="tech-flow"
      style={{
        textAlign: "center",
        backgroundColor: "#f8f9fe",
      }}
    >
      <h1
        className="tech-flow-heading"
        style={{
          padding: "0px 190px",
          marginBottom: "80px",
          fontWeight: "bold",
          fontSize: "31px",
        }}
      >
        Best-in-class Speech API
      </h1>
      <div style={{ textAlign: "center" }}>
        <span className="dot">{index + 1}</span>
      </div>
      <Container>
        <Grid id="desktopGraphApi" container>
          <Grid item xs={12}>
            <img style={{ width: "100%" }} src={GraphAPI} />
          </Grid>
        </Grid>
        <Grid id="mobileGraphApi" contanier>
          <Grid item xs={12}>
            <Carousel
              selectedItem={index}
              onChange={(newIndex) => {
                setIndex(newIndex);
              }}
            >
              <div>
                <img
                  className="flow-img"
                  style={{ height: "98px" }}
                  src={CaptureAudio}
                  alt="Widget"
                />
                <p className="carouselPara">USER SIDE</p>
                <h2
                  style={{
                    color: "#222323",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Capture audio
                </h2>
                <p style={{ color: "#818383" }}>
                  From Microphone, Android/iOS, Robot, Hardware, and More…
                </p>
              </div>
              <div>
                <img
                  className="flow-img"
                  style={{ height: "98px" }}
                  src={SendAudioToServer}
                  alt="Widget"
                />
                <p className="carouselPara">SERVER SIDE</p>
                <h2
                  style={{
                    color: "#222323",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Send audio to our server
                </h2>
              </div>
              <div>
                <img
                  style={{ height: "98px" }}
                  src={AnalysisOnServer}
                  alt="Widget"
                />
                <p className="carouselPara">SERVER SIDE</p>
                <h2
                  style={{
                    color: "#222323",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Analysis on Server
                </h2>
                <p style={{ color: "#818383" }}>
                  Real-time decoding and Batch decoding
                </p>
              </div>
              <div>
                <img
                  style={{ height: "98px" }}
                  src={DisplayInMeaningfulManner}
                  alt="Widget"
                />
                <p className="carouselPara">USER SIDE</p>
                <h2
                  style={{
                    color: "#222323",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Display in a meaningful way UI/UX
                </h2>
              </div>
              <div>
                <img style={{ height: "98px" }} src={ParseData} alt="Widget" />
                <p className="carouselPara">USER SIDE</p>
                <h2
                  style={{
                    color: "#222323",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Parse Data
                </h2>
              </div>
              <div>
                <img
                  style={{ height: "98px" }}
                  src={ResultBackToClient}
                  alt="Widget"
                />
                <p className="carouselPara">SERVERSIDE</p>
                <h2
                  style={{
                    color: "#222323",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Results back to client
                </h2>
              </div>
            </Carousel>
            <div style={{ textAlign: "center" }}>
              <span
                className={`bar ${index === 0 ? "active" : "inactive"}`}
                onClick={() => setIndex(0)}
              ></span>
              <span
                className={`bar ${index === 1 ? "active" : "inactive"}`}
                onClick={() => setIndex(1)}
              ></span>
              <span
                className={`bar ${index === 2 ? "active" : "inactive"}`}
                onClick={() => setIndex(2)}
              ></span>
              <span
                className={`bar ${index === 3 ? "active" : "inactive"}`}
                onClick={() => setIndex(3)}
              ></span>
              <span
                className={`bar ${index === 4 ? "active" : "inactive"}`}
                onClick={() => setIndex(4)}
              ></span>
              <span
                className={`bar ${index === 5 ? "active" : "inactive"}`}
                onClick={() => setIndex(5)}
              ></span>
            </div>
          </Grid>
        </Grid>
      </Container>
      {false && (
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4}>
              <img
                className="flow-img"
                style={{ height: "98px" }}
                src={CaptureAudio}
                alt="Widget"
              />
              <p style={{ color: "#0149B4" }}>USER SIDE</p>
              <h2
                style={{
                  color: "#222323",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Capture audio
              </h2>
              <p style={{ color: "#818383" }}>
                From Microphone, Android/iOS, Robot, Hardware, and More…
              </p>
            </Grid>
            <Grid className="flow-img" item xs={12} sm={4}>
              <img
                className="flow-img"
                style={{ height: "98px" }}
                src={SendAudioToServer}
                alt="Widget"
              />
              <p style={{ color: "#0149B4" }}>Server side</p>
              <h2
                style={{
                  color: "#222323",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Send audio to our server
              </h2>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                style={{ height: "98px" }}
                src={AnalysisOnServer}
                alt="Widget"
              />
              <p style={{ color: "#0149B4" }}>Server side</p>
              <h2
                style={{
                  color: "#222323",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Analysis on Server
              </h2>
              <p style={{ color: "#818383" }}>
                Real-time decoding and Batch decoding
              </p>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "50px" }} container spacing={0}>
            <Grid item xs={12} sm={4}>
              <img
                style={{ height: "98px" }}
                src={DisplayInMeaningfulManner}
                alt="Widget"
              />
              <p style={{ color: "#0149B4" }}>User side</p>
              <h2
                style={{
                  color: "#222323",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Display in a meaningful way UI/UX
              </h2>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img style={{ height: "98px" }} src={ParseData} alt="Widget" />
              <p style={{ color: "#0149B4" }}>User side</p>
              <h2
                style={{
                  color: "#222323",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Parse Data
              </h2>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                style={{ height: "98px" }}
                src={ResultBackToClient}
                alt="Widget"
              />
              <p style={{ color: "#0149B4" }}>Server side</p>
              <h2
                style={{
                  color: "#222323",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                Results back to client
              </h2>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}

export default TechnologyFlow;

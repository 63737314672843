import React from "react";
import { Container, Grid, Button } from "@material-ui/core";
import Nvidia from "../images/1_N55RJiGdQ5WxjQHGUk26mQ.png";
import awsActivate from "../images/aws_activate.png";
import KadhoLogo from "../images/KADHO_LOGO.png";
import KidX from "../images/KidX.AI .png";
import LG from "../images/LG_U+_logo.png";
import Oracle from "../images/Oracle-Logo.png";
import AWSEdstart from "../images/partners/AWSEdstart.jpg";
import AWSPartnerNetwork from "../images/partners/AWSPartnerNetwork.png";
import Sayve from "../images/partners/sayve.png";

export default function Main() {
  return (
    <div className="partners" style={{ padding: "120px 0px" }}>
      <Grid container spacing={2} style={{ justifyContent: "space-around" }}>
        <Grid
          className="widget-item"
          item
          sm={2}
          xs={6}
          style={{ justifyContent: "flex-start" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <img
              style={{ width: "130px", marginTop: "0px" }}
              src={Nvidia}
              alt="Widget"
            />
          </div>
        </Grid>
        <Grid
          className="widget-item"
          item
          sm={2}
          xs={6}
          style={{ justifyContent: "flex-start" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <img
              style={{ width: "130px", marginTop: "0px" }}
              src={awsActivate}
              alt="Widget"
            />
          </div>
        </Grid>
        <Grid
          className="widget-item"
          item
          sm={2}
          xs={6}
          style={{ justifyContent: "flex-start" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <img
              style={{ width: "130px", marginTop: "0px" }}
              src={LG}
              alt="Widget"
            />
          </div>
        </Grid>
        <Grid
          className="widget-item"
          item
          sm={2}
          xs={6}
          style={{ justifyContent: "flex-start" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <img
              onClick={() =>
                (window.location.href = "https://aws.amazon.com/awsedstart")
              }
              style={{
                width: "80px",
                cursor: "pointer",
                marginTop: "0px",
                filter: "grayscale(100%)",
              }}
              src={AWSEdstart}
              alt="Widget"
            />
          </div>
        </Grid>
        <Grid
          className="widget-item"
          item
          sm={2}
          xs={6}
          style={{ justifyContent: "flex-start" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <img
              style={{
                width: "80px",
                marginTop: "0px",
                filter: "grayscale(100%)",
              }}
              src={AWSPartnerNetwork}
              alt="Widget"
            />
          </div>
        </Grid>
        <Grid
          className="widget-item"
          item
          sm={2}
          xs={6}
          style={{ justifyContent: "flex-start" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <img
              style={{
                width: "80px",
                marginTop: "0px",
                filter: "grayscale(100%)",
              }}
              src={Sayve}
              alt="Widget"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

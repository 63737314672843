import React from "react";
import Soapbox from "../images/soapbox.png";
import Footer from "../images/footer.png";
import { Grid, Container } from "@material-ui/core";
import { height } from "@material-ui/system";

function Foot() {
  const list = {
    listStyle: "none",
    fontWeight: "600",
    padding: "0px",
  };
  return (
    <>
      <div
        className="footer-desktop"
        style={{ backgroundColor: "#222323", color: "#fff" }}
      >
        <Container>
          <Grid container style={{ padding: "80px 0px" }}>
            <Grid style={{ padding: "0px 14px" }} item sm={4} xs={12}>
              <h1 style={{ marginTop: "0px" }}>aispeak</h1>
              <p style={{ fontSize: "15px" }}>Instantly check your speaking</p>
            </Grid>
            <Grid style={{ padding: "0px 40px" }} item sm={4} xs={12}>
              <ul className="footer-list" style={list}>
                <li>
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    style={{ color: "#fff", textDecoration: "none" }}
                    href="/technology"
                  >
                    Technology
                  </a>
                </li>

                <li>Resources</li>
              </ul>
            </Grid>
            <Grid style={{ padding: "0px 40px" }} item sm={4} xs={12}>
              <h4>Contact</h4>
              <p>info@aispeak.com</p>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid
            className="footer-lower"
            container
            style={{ padding: "80px 14px" }}
          >
            <Grid item sm={4} xs={12}>
              <p>All rights reserved. aispeak inc</p>
            </Grid>
            <Grid item sm={4} xs={12}></Grid>
            <Grid item sm={4} xs={12}>
              <p style={{ textAlign: "right" }}>
                Privacy Policy &nbsp; Terms of Use
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        className="footer-mobile"
        style={{ backgroundColor: "#222323", color: "#fff" }}
      >
        <Container>
          <Grid container style={{ padding: "48px 0px 0px 0px" }}>
            <Grid
              style={{
                padding: "0px 40px",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "600",
              }}
              item
              sm={4}
              xs={12}
            >
              <ul className="footer-list" style={list}>
                <li>
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    style={{ color: "#fff", textDecoration: "none" }}
                    href="/technology"
                  >
                    Technology
                  </a>
                </li>

                <li>Resources</li>
              </ul>
            </Grid>
            <Grid
              style={{
                padding: "0px 40px",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "600",
                marginTop: "16px",
              }}
              item
              sm={4}
              xs={12}
            >
              <h4>Contact</h4>
              <p>info@aispeak.com</p>
            </Grid>
          </Grid>
        </Container>
        <div className="footer-bar"></div>
        <Container>
          <Grid container style={{ padding: "32px 14px 48px" }}>
            <Grid
              style={{ padding: "0px 14px", textAlign: "center" }}
              item
              sm={4}
              xs={12}
            >
              <h1 style={{ marginTop: "0px" }}>aispeak</h1>
              <p style={{ fontSize: "13px", marginBottom: "32px" }}>
                Instantly check your speaking
              </p>
            </Grid>
            <Grid item sm={4} xs={12}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "13px",
                  fontWeight: "400",
                  marginBottom: "0px",
                }}
              >
                Privacy Policy &nbsp; Terms of Use
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Foot;

import React from "react";
import { Grid, Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const style = {
  Paper: {
    padding: "80px 0px",
    marginLeft: "15px",
    marginRight: "15px",
  },
};

function TechnologyCard() {
  return (
    <div id="techMain">
      <Container>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Paper className="blog-paper-tech">
              <h1 style={{ color: "#0149B4", fontWeight: "bold" }}>83,225+</h1>
              <p style={{ fontWeight: "bold" }}>API Daily Use</p>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper className="blog-paper-tech">
              <h1 style={{ color: "#0149B4", fontWeight: "bold" }}>
                10 Million+
              </h1>
              <p style={{ fontWeight: "bold" }}>Total API Usage</p>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper className="blog-paper-tech">
              <h1 style={{ color: "#0149B4", fontWeight: "bold" }}>
                1 MIllion+
              </h1>
              <p style={{ fontWeight: "bold" }}>Total Minutes of Data</p>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default TechnologyCard;

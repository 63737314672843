import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Illustration2 from "../../images/Illustration_point2.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UseCasePhrases() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="tech-usecase-img-container" onClick={handleOpen}>
        <img src={Illustration2} alt="Widget" />
      </div>
      <Modal
        style={{ outline: "none" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // disableScrollLock={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="phrases">
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: "#222323",
                width: "22px",
                heigt: "2px",
                marginTop: "40px",
                marginRight: "40px",
                display: "block",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            />
            <h1>Sentences</h1>
            <div className="phrases-div1">
              <h3>Target Phrase</h3>
              <h1>Hi, do you have a table for two at eight o’clock tonight?</h1>
              <div id="phrasesContainer">
                <div className="phrases-stats">
                  <h1>
                    Completeness <span style={{ color: "#1371E2" }}>100</span>
                  </h1>
                  <div className="completeness"></div>
                  <p>Missed words: None</p>
                </div>
                <div className="phrases-stats">
                  <h1>
                    Accuracy <span style={{ color: "#1371E2" }}>90</span>
                  </h1>
                  <div className="accuracy">
                    <div></div>
                  </div>

                  <p>Words to practice: None</p>
                </div>
                <div className="phrases-stats">
                  <h1>
                    Fluency <span style={{ color: "#1371E2" }}>96</span>
                  </h1>
                  <div className="fluency">
                    <div></div>
                  </div>
                  <p>Stutters: None</p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

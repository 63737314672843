import React from "react";

function TechnologyBandGreen() {
  return (
    <div
      className="tech-green-band"
      style={{
        backgroundColor: "#6AD5C3",
        padding: "100px 80px",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <h1>
        Our algorithm is able to identify key patterns in pronunciation,
        fluency, and intonation to give users comprehensive insight to their
        speaking abilities.
      </h1>
    </div>
  );
}

export default TechnologyBandGreen;

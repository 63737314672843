import React from "react";
import { Grid, Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import FlagUsa from "../images/flag-united-states.svg";
import FlagChina from "../images/flag-china.svg";
import FlagKorea from "../images/flag-korea.svg";
import FlagFrance from "../images/flag-france.svg";
import FlagSpain from "../images/flag-spain.svg";
import FlagGermany from "../images/flag-germany.svg";
import FlagJapan from "../images/Flag_Japan.svg";

function Main() {
  return (
    <div className="blog-section">
      <Container>
        <div style={{ color: "#222323" }}>
          <h1
            className="blog-section-heading"
            style={{
              fontWeight: "bold",
              marginBottom: "88px",
              fontSize: "31px",
            }}
          >
            Available in these languages
          </h1>
        </div>

        <Grid container>
          <Grid item xs={12} sm={4}>
            <Paper className="blog-paper-main">
              <img src={FlagUsa} alt="english" />
              <h1 style={{ fontWeight: "bold", fontSize: "31px" }}>English</h1>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper className="blog-paper-main">
              <img src={FlagChina} alt="english" />
              <h1 style={{ fontWeight: "bold", fontSize: "31px" }}>Chinese</h1>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper className="blog-paper-main">
              <img src={FlagKorea} alt="english" />
              <h1 style={{ fontWeight: "bold", fontSize: "31px" }}>Korean</h1>
            </Paper>
          </Grid>
        </Grid>

        <div className="desktop-blogPaperComing" style={{ color: "#222323" }}>
          <h1
            style={{
              fontWeight: "bold",
              marginBottom: "60px",
              marginTop: "80px",
              fontSize: "20px",
              color: "#818383",
            }}
          >
            Coming Soon
          </h1>
        </div>
        <h1 className="mobile-blogPaperComing">Coming Soon </h1>
        <Grid container>
          <Grid item xs={6} sm={3}>
            <Paper className="blog-paper blogPaperComing">
              <img
                style={{
                  width: "100%",
                  height: "50px",
                }}
                src={FlagSpain}
                alt="english"
              />
            </Paper>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Paper className="blog-paper blogPaperComing">
              <img
                style={{
                  width: "100%",
                  height: "50px",
                }}
                src={FlagFrance}
                alt="english"
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className="blog-paper blogPaperComing">
              <img
                style={{
                  width: "100%",
                  height: "50px",
                }}
                src={FlagJapan}
                alt="english"
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper className="blog-paper blogPaperComing">
              <img
                style={{
                  width: "100%",
                  height: "50px",
                }}
                src={FlagGermany}
                alt="english"
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Main;

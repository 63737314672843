import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    "& > * + *": {},
  },
}));

export default function Chart() {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root} style={{ margin: "auto" }}>
      <div style={{ margin: "auto", position: "relative" }}>
        <div id="value">
          <CircularProgress variant="static" value={88} size={80} />
        </div>
        <div id="base">
          <CircularProgress variant="static" value={100} size={80} />
        </div>
        <div id="score">
          <span>88</span>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Container from "@material-ui/core/Container";
import Main from "../components/main";
import Widget from "../components/widget";
import Partner from "../components/partners";
import Blog from "../components/blog";
import Card from "../components/card";
import Technology from "../components/technoloy";
import Media from "../components/media";
import Demo from "../components/demo";
import Feature from "../components/feature";
import Landing_Top from "../images/landing-top.svg";

function Home() {
  return (
    <div id="main-div">
      <Container id="main-div-grid">
        <Main />
      </Container>
      <img id="mobileLanding" src={Landing_Top} alt="Main" />

      <Container id="widget" style={{ paddingLeft: "39px", marginTop: "80px" }}>
        <Widget />
      </Container>
      <div style={{ backgroundColor: "#F8FAFE" }}>
        <Container style={{ paddingLeft: "39px" }}>
          <Partner />
        </Container>
      </div>
      {/* <div
        style={{
          paddingTop: "100px",
          paddingBottom: "100px",
          marginTop: "140px",
          background: "#F8FAFE"
        }}
      >
        <Container>
          <Blog />
        </Container>
      </div> */}
      <div
        id="ai-usecase"
        style={{ paddingTop: "100px", paddingBottom: "100px" }}
      >
        <Container>
          <Card />
        </Container>
      </div>
      <Technology />
      <Media />
      <Feature />

      <Demo />
    </div>
  );
}

export default Home;

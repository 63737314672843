import React from "react";
import { Container, Grid, Button } from "@material-ui/core";
import Icon from "../images/ai_small.svg";
import MainPoint2 from "../images/main point_2.svg";
import MainPoint3 from "../images/scalable.svg";

export default function Main() {
  return (
    <div className="widget-item-div" style={{ padding: "168px 0px 120px" }}>
      <Grid container spacing={2}>
        <Grid
          style={{
            textAlign: "center",
            justifyContent: "center",
            padding: 0,
            maxWidth: "65px",
            margin: "0 auto",
          }}
          className="widget-item"
          item
          xs={12}
          sm={1}
        >
          <img
            style={{ height: "60px", marginTop: "15px" }}
            src={Icon}
            alt="Widget"
          />
        </Grid>
        <Grid
          className="widget-item"
          item
          sm={3}
          xs={12}
          style={{ flexDirection: "column" }}
        >
          <h1
            style={{ fontSize: "25px", fontWeight: "600", lineHeight: "1.28" }}
          >
            True AI
          </h1>
          <p style={{ fontSize: "16px", color: "#818383", width: "90%" }}>
            Check your speaking instantly with 93% accuracy{" "}
          </p>
        </Grid>
        <Grid
          style={{
            textAlign: "center",
            justifyContent: "center",
            padding: 0,
            maxWidth: "65px",
            margin: "0 auto",
          }}
          className="widget-item"
          item
          xs={12}
          sm={1}
          style={{ flexDirection: "column" }}
        >
          <img
            id="multilingualImge"
            style={{ height: "60px", marginTop: "15px" }}
            src={MainPoint2}
            alt="Widget"
          />
        </Grid>
        <Grid
          className="widget-item"
          item
          sm={3}
          xs={12}
          style={{ flexDirection: "column" }}
        >
          <h1
            style={{ fontSize: "25px", fontWeight: "600", lineHeight: "1.28" }}
          >
            Multilingual
          </h1>
          <p style={{ fontSize: "16px", color: "#818383", width: "90%" }}>
            Support for major languages of the world{" "}
          </p>
        </Grid>
        <Grid
          style={{
            textAlign: "center",
            justifyContent: "center",
            padding: 0,
            maxWidth: "65px",
            margin: "0 auto",
          }}
          className="widget-item"
          item
          sm={1}
          xs={12}
        >
          <img
            id="scalableImg"
            style={{ height: "60px", marginTop: "15px" }}
            src={MainPoint3}
            alt="Widget"
          />
        </Grid>
        <Grid
          className="widget-item"
          item
          sm={3}
          xs={12}
          style={{ flexDirection: "column" }}
        >
          <h1
            style={{ fontSize: "25px", fontWeight: "600", lineHeight: "1.28" }}
          >
            Scalable
          </h1>
          <p style={{ fontSize: "16px", color: "#818383", width: "90%" }}>
            Built to service individual learners to institutions
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

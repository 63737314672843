import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";
import Precise from "../images/precise.svg";
import Customizable from "../images/customizable.svg";
import Scalable from "../images/scalable.svg";
import Comprehensive from "../images/comprehensive.svg";

export default function FullWidthGrid() {
  return (
    <Container>
      <div
        id="fullWidthGrid"
        style={{
          marginTop: "121px",
          paddingBottom: "120px",
          textAlign: "center",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sm={3}>
            <img style={{ height: "96px" }} src={Precise} alt="Widget" />
            <h2
              className="full-WidthGrid"
              style={{
                color: "#222323",
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "52px",
                marginBottom: "16px",
              }}
            >
              Intelligent and Precise
            </h2>
            <p>Adaptive AI engine allows for 93% accuracy</p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img style={{ height: "96px" }} src={Customizable} alt="Widget" />
            <h2
              className="full-WidthGrid"
              style={{
                color: "#222323",
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "52px",
                marginBottom: "16px",
              }}
            >
              Customizable
            </h2>
            <p>Adapt scoring to different level and different purposes</p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img style={{ height: "96px" }} src={Scalable} alt="Widget" />
            <h2
              className="full-WidthGrid"
              style={{
                color: "#222323",
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "52px",
                marginBottom: "16px",
              }}
            >
              Real time and Scalable
            </h2>
            <p>
              Assess as you speak, or assess in batches (thousand of files) at
              once
            </p>
          </Grid>

          <Grid item xs={12} sm={3}>
            <img style={{ height: "96px" }} src={Comprehensive} alt="Widget" />
            <h2
              id="comprehensive"
              style={{
                color: "#222323",
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "52px",
                marginBottom: "16px",
              }}
            >
              Comprehensive
            </h2>
            <p>Pronunciation, Intonation, Fluency, Homograph, Tones</p>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

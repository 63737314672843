import React from "react";
import { Grid } from "@material-ui/core";
import Landing_Top from "../images/landing-top.svg";

function Main() {
  return (
    <div id="homeMain">
      <Grid
        id="homeMainTopGrid"
        container
        spacing={0}
        style={{
          paddingTop: "160px",
          paddingLeft: "15px",
          marginLeft: 0,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Grid className="main-left" item xs={12} sm={6}>
          <h1
            id="instantlyCheckSpeaking"
            style={{
              fontWeight: "600",
              lineHeight: "1.245",
              fontSize: "61.04px",
              color: "#222323",
              marginBottom: "30px",
              width: "500px",
            }}
          >
            Instantly check your speaking
          </h1>
          <p style={{ fontSize: "20px", lineHeight: "1.6", color: "#818383" }}>
            Anywhere, anytime, precise and real-time
          </p>
          <Grid container spacing={0} style={{ paddingTop: 20 }}>
            <Grid item xs={12}>
              <button
                onClick={() => (window.location.href = "/contact")}
                style={{
                  fontSize: "16px",
                }}
                className="button-main"
              >
                Try our API for free
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <img id="mobileLanding" src={Landing_Top} alt="Main" /> */}
    </div>
  );
}

export default Main;

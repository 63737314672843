import React from "react";
import { Grid, Container } from "@material-ui/core";

function Main() {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={6}></Grid>
        <Grid style={{ paddingRight: "140px" }} item xs={12} sm={6}>
          <p
            style={{
              fontSize: "13px",
              color: "#0149B4",
              fontFamily: "Arial",
              fontWeight: "600",
              lineHeight: "1.25"
            }}
          >
            FROM THE BLOG
          </p>
          <p
            style={{
              fontSize: "39px",
              color: "#222323",
              fontWeight: "600",
              lineHeight: "1.4"
            }}
          >
            The New A.I Technology to Assess Speech Has Come
          </p>
          <p
            style={{
              fontSize: "16px",
              color: "#818383",
              fontWeight: "regular"
            }}
          >
            Quisque suscipit rutrum lorem. Vivamus a neque hendrerit, varius
            elit eget, sceleri sque nulla rutrum lorem. Quisque suscipit rutrum
            lorem. Vivamus a neque hendrerit, varius elit eget.
          </p>
          <p
            style={{
              fontSize: "16",
              color: "#222323",
              fontWeight: "600"
            }}
          >
            Read The Post<span style={{ paddingLeft: "12px" }}>></span>
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Main;

import React from "react";
import { Grid, Container } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
function Demo() {
  let history = useHistory();
  return (
    <div>
      <Grid
        className="requestForDemo"
        container
        style={{ padding: "150px 0", textAlign: "center" }}
      >
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <div>
            {" "}
            <h1 style={{ fontWeight: "bold", fontSize: "61px" }}>
              Request For a Demo
            </h1>
            <p
              style={{ fontSize: "20px", color: "#818383", marginTop: "24px" }}
            >
              Find out how aispeak can help you
            </p>
          </div>
          <button
            className="button-main"
            onClick={() => history.push("/contact")}
            style={{ marginTop: 30 }}
          >
            Contact Us
          </button>
        </Grid>
        <Grid item sm={2} xs={12}></Grid>
      </Grid>
    </div>
  );
}

export default Demo;

import React from "react";

function TechnologyBand() {
  return (
    <div
      className="tech-blue-band"
      style={{
        backgroundColor: "#0149B4",
        padding: "100px 113px",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <h1 id="propMessage" style={{ fontWeight: "bold", fontSize: "39px" }}>
        Proprietary AI technology with DNN model based on thousands of hours of
        native speakers' speech.
        <br />
        <span style={{ color: "#6AD5C3" }}>
          We offer you real-time precise speech assessment.
        </span>
      </h1>
    </div>
  );
}

export default TechnologyBand;

import React from "react";

function TechnologyBand() {
  return (
    <div
      className="tech-lower-band"
      style={{
        backgroundColor: "#f8f9fe",
        padding: "100px 150px",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <h1 style={{ color: "#222323" }}>Want to Know more?</h1>
      <p style={{ color: "#818383" }}>Instantly check your speaking</p>
      <button className="button-main">Contact Us</button>
    </div>
  );
}

export default TechnologyBand;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faTimes,
  faCheck,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import Illustration6 from "../../images/Illustration_point6.svg";
import Illustration3 from "../../images/Illustration_point3.svg";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
  },
  paper: {
    // backgroundColor:,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function UseCaseQandA({ type }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {type === "qna" ? (
        <div className="tech-usecase-img-container" onClick={handleOpen}>
          <img src={Illustration6} alt="Widget" />
        </div>
      ) : (
        <div className="tech-usecase-img-container" onClick={handleOpen}>
          <img src={Illustration3} alt="Widget" />
        </div>
      )}
      <Modal
        style={{ outline: "none" }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="qAndA">
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: "#222323",
                width: "22px",
                heigt: "2px",
                marginTop: "40px",
                marginRight: "40px",
                display: "block",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            />
            <h1>Q&A</h1>
            <div className="qAndA-main">
              {type === "qna" ? (
                <div className="qAndA-1">
                  <h1>What did Snow White eat in the story?</h1>
                  <p>A.Orange</p>
                  <p style={{ color: "#1371E2" }}>B.Apple</p>
                  <p>c.Watermelon</p>
                  <p>D.Durian</p>
                  <p style={{ color: "#1371E2", marginTop: "10px" }}>
                    <FontAwesomeIcon icon={faCheck} />
                    {"  "}Correct
                  </p>
                  <div className="qAndA-icons">
                    <div>
                      <FontAwesomeIcon
                        size={"2x"}
                        style={{ color: "#818383" }}
                        icon={faRedoAlt}
                      />
                      <span>Retry</span>
                    </div>

                    <div
                      style={{
                        height: "60px",
                        width: "60px",
                        backgroundColor: "#2d4fe5",
                        borderRadius: "50%",
                        cursor: "pointer",
                        margin: "0px 40px 27px",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          height: "23px",
                          width: "23px",
                          borderRadius: "50%",
                          margin: "18px",
                          color: "#fff",
                        }}
                        icon={faMicrophone}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="qAndA-2">
                  <h1>How is the weather today?</h1>
                  <p>Hint</p>
                  <p>The weather is very warm today.</p>
                  <p>It is so cold today.</p>
                  <p>It is so hot today!</p>
                  <p style={{ color: "#1371E2", marginTop: "10px" }}>
                    <FontAwesomeIcon icon={faCheck} />
                    {"  "}Correct
                  </p>
                  <div className="qAndA-icons">
                    <div>
                      <FontAwesomeIcon
                        size={"2x"}
                        style={{ color: "#818383" }}
                        icon={faRedoAlt}
                      />
                      <span>Retry</span>
                    </div>

                    <div
                      style={{
                        height: "60px",
                        width: "60px",
                        backgroundColor: "#2d4fe5",
                        borderRadius: "50%",
                        cursor: "pointer",
                        margin: "0px 40px 27px",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{
                          height: "23px",
                          width: "23px",
                          borderRadius: "50%",
                          margin: "18px",
                          color: "#fff",
                        }}
                        icon={faMicrophone}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
